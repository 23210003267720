import Amplify from "aws-amplify";
import { IAuthPieceProps } from "aws-amplify-react/lib-esm/Auth/AuthPiece";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { CustomAuthenticator } from "./aws";
import awsconfig from "./aws-config";
import useUserStatus from "./hooks/useUserStatus";
import AppLayout from "./layout/AppLayout";
import { Routes } from "./routes/routes";

Amplify.configure(awsconfig);

const App: React.FC<IAuthPieceProps> = (props) => {
  const user = useUserStatus();

  return props.authState === "signedIn" ? (
    <AppLayout>{user && <Routes />}</AppLayout>
  ) : null;
};

const WrappedApp: React.FC = () => (
  <BrowserRouter>
    <CustomAuthenticator>
      <App />
    </CustomAuthenticator>
  </BrowserRouter>
);

export default WrappedApp;
