import {
  HomeOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
  AppstoreOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Typography, Badge } from "antd";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import zamowieniaOptions from "../content/zamowieniaOptions";

interface Props {}

const AppLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [userData, setUserData] = useState<any>({});

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((res) => {
      setUserData(res);
    });
  }, []);

  const signOut = () => {
    Auth.signOut();
    history.push("/");
  };

  const subMenuKeys = ["/zamowienia"];

  const NavMenu = () => (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname]}
      defaultOpenKeys={subMenuKeys}
    >
      <Menu.Item key="/">
        <Link to="/">
          <HomeOutlined className="nav-icon" />
          <span>Strona główna</span>
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        key="/zamowienia"
        title={
          <>
            <AppstoreOutlined className="nav-icon" />
            <span>Zamówienia</span>
          </>
        }
      >
        {zamowieniaOptions.map((option) => (
          <Menu.Item key={"/zamowienia/" + option.routeName}>
            <Link to={"/zamowienia/" + option.routeName}>
              <Badge color={option.color} />
              {option.text}
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.Item key="/apteki">
        <Link to="/apteki">
          <UnorderedListOutlined className="nav-icon" />
          <span>Apteki</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/lekarze">
        <Link to="/lekarze">
          <MedicineBoxOutlined className="nav-icon" />
          <span>Lekarze</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/pacjenci">
        <Link to="/pacjenci">
          <TeamOutlined className="nav-icon" />
          <span>Pacjenci</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/uzytkownicy">
        <Link to="/uzytkownicy">
          <UserOutlined className="nav-icon" />
          <span>Użytkownicy</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/wsparcie">
        <Link to="/wsparcie">
          <MailOutlined className="nav-icon" />
          <span>Wsparcie</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout>
      <Layout.Sider className="sider" width={250}>
        <div className="logo">
          <Typography.Title level={2} style={{ color: "#fff", margin: 0 }}>
            MigrenaSOS
          </Typography.Title>
        </div>
        <NavMenu />
      </Layout.Sider>
      <Layout style={{ height: "100vh" }}>
        <Layout.Header
          className="site-header"
          style={{ backgroundColor: "#fff" }}
        >
          Witaj,{" "}
          <span className="primary-text">{userData.attributes?.email}</span>
          <Button className="logout-button" type="primary" onClick={signOut}>
            Wyloguj się
          </Button>
        </Layout.Header>
        <Layout.Content style={{ padding: 20, height: "calc(100vh - 64px)" }}>
          <main className="main">{children}</main>
        </Layout.Content>
        {/* <Layout.Footer style={{ textAlign: "center" }}>
          Ant Design ©2018 Created by Ant UED
        </Layout.Footer> */}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
