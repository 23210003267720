import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import Axios from "axios";

// import { setAuthHeader } from "../utils/axiosClient";

// This hook provides access to currently logged in CognitoUser
// It listens to Hub "auth" event and updates returned value if user signs in or out
// It returns null if promise has not yet resolved, false if user is not signed in and CognitoUser object is user is signed in

function useUserStatus(): null | CognitoUser | false {
  const [user, setUser] = useState<null | CognitoUser | false>(null);

  useEffect(() => {
    const updateUser = () => {
      Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          // const idToken = user
          //   .getSignInUserSession()
          //   ?.getIdToken()
          //   .getJwtToken();
          // setAuthHeader(idToken!);
          setUser(user);
          const idToken = user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken();

          Axios.defaults.headers.common["Authorization"] = "Bearer " + idToken;
        })
        .catch(err => {
          setUser(false);
        });
    };
    Hub.listen("auth", updateUser);
    updateUser();
    return () => Hub.remove("auth", updateUser);
  }, []);

  return user;
}

export default useUserStatus;
