import { Button, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import { SignIn } from "aws-amplify-react";
import React, { FormEvent } from "react";
import Helmet from "react-helmet";

import AuthLayout from "../layout/AuthLayout";

export class CustomSignIn extends SignIn {
  _validAuthStates = ["signIn", "signedOut", "signedUp"];

  formRef = React.createRef<FormInstance>();

  state = {
    loading: false,
  };

  handleSubmit = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.formRef
      .current!.validateFields()
      .then((values) => {
        this.setState((prev) => ({ ...prev, loading: true }));
        super
          .signIn(e)
          .then(() => {
            this.setState((prev) => ({ ...prev, loading: false }));
          })
          .catch((err) => {
            console.log(err);
            this.setState((prev) => ({ ...prev, loading: false }));
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showComponent() {
    return (
      <AuthLayout>
        <Helmet>
          <title>Logowanie | MigrenaSOS</title>
        </Helmet>
        <Form
          onSubmitCapture={this.handleSubmit}
          ref={this.formRef}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          hideRequiredMark
        >
          <Form.Item
            name="username"
            label="E-mail"
            rules={[
              { type: "email", message: "To nie jest poprawny e-mail." },
              {
                required: true,
                message: "E-mail nie może być pusty.",
              },
              {
                whitespace: true,
                message: "E-mail nie może być pusty.",
              },
            ]}
          >
            <Input
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Hasło"
            rules={[
              {
                required: true,
                message: "Hasło jest wymagane.",
              },
              {
                whitespace: true,
                message: "Hasło jest wymagane.",
              },
            ]}
          >
            <Input.Password
              id="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Button
            loading={this.state.loading}
            onClick={this.handleSubmit}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: 40, marginTop: 20 }}
          >
            Zaloguj
          </Button>
        </Form>
      </AuthLayout>
    );
  }
}
