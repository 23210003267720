import { Typography } from "antd";
import { AuthPiece } from "aws-amplify-react";
import React from "react";

export interface IAuthLayoutProps {}

export interface IAuthLayoutState {}

export default class AuthLayout extends AuthPiece<
  IAuthLayoutProps,
  IAuthLayoutState
> {
  _validAuthStates = ["signIn", "signedOut", "signedUp"];

  public render() {
    return (
      <div className="auth-layout">
        <div className="auth-wrapper">
          <Typography.Title className="auth-title">MigrenaSOS</Typography.Title>
          {this.props.children}
        </div>
      </div>
    );
  }
}
