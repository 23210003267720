import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import zamowieniaOptions from "../content/zamowieniaOptions";

const Apteki = React.lazy(() => import("../pages/apteki"));
const Dashboard = React.lazy(() => import("../pages"));
const Lekarze = React.lazy(() => import("../pages/lekarze"));
const Pacjenci = React.lazy(() => import("../pages/pacjenci"));
const Uzytkownicy = React.lazy(() => import("../pages/uzytkownicy"));
const Wsparcie = React.lazy(() => import("../pages/wsparcie"));
const ZamowieniaTemplate = React.lazy(() =>
  import("../pages/zamowienia/[:routeName]")
);

export const Routes: React.FC = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/apteki" component={Apteki} />
      <Route exact path="/lekarze" component={Lekarze} />
      <Route exact path="/pacjenci" component={Pacjenci} />
      <Route exact path="/uzytkownicy" component={Uzytkownicy} />
      <Route exact path="/wsparcie" component={Wsparcie} />
      {zamowieniaOptions.map(option => (
        <Route
          key={option.status}
          exact
          path={`/zamowienia/${option.routeName}`}
          render={props => (
            <ZamowieniaTemplate {...props} zamowienieOption={option} />
          )}
        />
      ))}
      <Redirect to="/" />
    </Switch>
  </Suspense>
);
