const zamowieniaOptions: ZamowienieOption[] = [
  {
    status: "new",
    color: "orange",
    text: "Do wysłania",
    routeName: "do-wyslania"
  },
  {
    status: "notification",
    color: "cyan",
    text: "Wysłane",
    routeName: "wyslane"
  },
  {
    status: "done",
    color: "green",
    text: "Odebrane",
    routeName: "odebrane"
  },
  {
    status: "warning",
    color: "yellow",
    text: "Nieodebrane",
    routeName: "nieodebrane"
  },
  {
    status: "urgent",
    color: "volcano",
    text: "Ponownie nieodebrane",
    routeName: "ponownie-nieodebrane"
  }
];

export default zamowieniaOptions;
