import {
  Authenticator,
  Greetings,
  RequireNewPassword,
  SignIn
} from "aws-amplify-react";
import React from "react";

import awsconfig from "../aws-config";
import { CustomRequireNewPassword, CustomSignIn } from "./";
import ParticlesBackground from "./ParticlesBackground";

interface Props {}

export const CustomAuthenticator: React.FC<Props> = props => (
  <Authenticator
    hide={[SignIn, Greetings, RequireNewPassword]}
    amplifyConfig={awsconfig}
  >
    <CustomRequireNewPassword />
    <CustomSignIn />
    <ParticlesBackground />
    {props.children}
  </Authenticator>
);

export default CustomAuthenticator;
